import { useMemo } from 'react';

import { useStore } from '@/src/store';
import { useForexStore } from '@clubpay/payment-kit';
import { Typography } from '@mui/material';

import SaudiRiyalIcon from './symbols/SaudiRiyalIcon';

type Props = {
  variant: 'currency' | 'title_lg';
  color?: string;
  ignoreForex?: boolean;
  currencyOverride?: string;
};

const Currency = ({
  variant,
  color,
  ignoreForex = false,
  currencyOverride,
}: Props) => {
  const initialLink = useStore(state => state.initialLink);
  const forex = useForexStore();

  const currency = useMemo(() => {
    if (currencyOverride) return currencyOverride.toUpperCase();
    if (!ignoreForex && forex?.isApplied) {
      return (forex.currencyCode || '').toUpperCase();
    }
    return (initialLink?.data.currency || '').toUpperCase();
  }, [forex.isApplied, forex?.currencyCode, initialLink?.data.currency]);

  const getIconSize = () => {
    switch (variant) {
      case 'currency':
        return 'medium';
      case 'title_lg':
        return 'large';
      default:
        return 'medium';
    }
  };

  if (currency === 'SAR')
    return <SaudiRiyalIcon fontSize={getIconSize()} htmlColor={color} />;
  if (currency)
    return (
      <Typography variant={variant} display='inline' color={color}>
        {currency}
      </Typography>
    );
};

export default Currency;
