import { memo, useMemo } from 'react';

import Currency from '@/components/common/currency';
import { useCheckLinkUpdates, usePaymentLink } from '@/hooks';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';

import styles from './styles.module.scss';

const LinkDetails = memo(function LinkDetails() {
  const { data } = usePaymentLink({ shouldUpdateStatus: true });

  useCheckLinkUpdates();

  const billAmount = useMemo(() => {
    let amount = Number(data?.bill.billAmount || 0);
    return amount.toFixed(data?.currencyPrecision || 2);
  }, [data?.bill.billAmount, data?.currencyPrecision]);

  return (
    <>
      <Container className={styles.linkDetailsContainer}>
        <Typography variant='body_sm'>{data?.meta?.reference}</Typography>
        <Box display='flex' flexDirection='row' gap='6px' alignItems='baseline'>
          <Currency variant='title_lg' ignoreForex />
          <Typography variant='title_lg'>{billAmount}</Typography>
        </Box>
      </Container>
      {data?.meta?.description && (
        <>
          <Container className={styles.descriptionContainer}>
            <Typography
              variant='caption'
              color='var(--onSurfaceColors-highEmphasis)'
              whiteSpace='pre-wrap'
            >
              {data?.meta?.description}
            </Typography>
          </Container>
          <Divider />
        </>
      )}
      {data?.meta?.customInfoFields && (
        <>
          <Container className={styles.extraInfoContainer}>
            <Stack gap='12px'>
              {data?.meta?.customInfoFields
                ?.filter(field => field.isPublic && field.value)
                .map((field, index) => (
                  <Box key={index}>
                    <Typography variant='subtitle2'>{field.name}</Typography>
                    <Typography variant='caption'>{field.value}</Typography>
                  </Box>
                ))}
            </Stack>
          </Container>
          <Divider />
        </>
      )}
    </>
  );
});

export default LinkDetails;
