import React, { useEffect, useState } from 'react';

import { useTranslation } from '@clubpay/customer-common-module/src/hook/translations';
import { IForex } from '@clubpay/payment-kit';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import {
  Button,
  SwipeableCustomerDrawer,
  Typography,
} from '@qlub-dev/qlub-kit';

import styles from './index.module.scss';

interface IProps {
  open: boolean;
  exchange?: IForex;
  vendorCurrency: string;
  total: number;
  currencyPrecision: number;
  onChange: (currencyCode: string | null) => void;
  onClose: () => void;
  onOpen: () => void;
}

const ChangeCurrency = ({
  open,
  exchange,
  vendorCurrency,
  total,
  currencyPrecision,
  onChange,
  onClose,
  onOpen,
}: IProps) => {
  const { t } = useTranslation('common');

  const initialCurrency = exchange?.isApplied
    ? exchange.currencyCode
    : vendorCurrency;

  const [selectedCurrency, setSelectedCurrency] = useState(initialCurrency);

  const confirmHandler = () => {
    onChange(selectedCurrency);
    onClose();
  };

  useEffect(() => {
    if (open) setSelectedCurrency(initialCurrency);
  }, [open]);

  return (
    <SwipeableCustomerDrawer
      anchor='bottom'
      open={open}
      headerTitle={t('exchange-currency')}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Typography
        typo='caption_overline'
        sx={{ color: '#616475' }}
        marginBottom={4}
      >
        {t('select-to-pay-with-one-of-the-below-options')}
      </Typography>

      <div className={styles.detailsContainer}>
        <Typography typo='title_md'>{t('pay-in')}</Typography>
        <RadioGroup
          value={selectedCurrency}
          className={styles.radioContainer}
          onChange={(_, value: string) => setSelectedCurrency(value)}
          row
        >
          <FormControlLabel
            value={exchange?.currencyCode}
            control={<Radio />}
            label={`${exchange?.currencyCode?.toUpperCase()}: ${exchange?.total?.toFixed(
              currencyPrecision,
            )}`}
          />
          <FormControlLabel
            value={vendorCurrency}
            control={<Radio />}
            label={`${vendorCurrency}: ${total?.toFixed(currencyPrecision)}`}
          />
        </RadioGroup>
        <Typography typo='caption_overline' sx={{ color: '#9B9EAB' }}>
          {t('exchange-rate')} 1 {vendorCurrency} = {exchange?.exchangeRate}{' '}
          {exchange?.currencyCode}
        </Typography>
      </div>

      <Stack spacing={2} className={styles.buttonContainer} marginTop={4}>
        <Button variant='contained' onClick={confirmHandler}>
          {t('confirm')}
        </Button>
        <Button variant='containedLight' onClick={onClose}>
          {t('cancel')}
        </Button>
      </Stack>
    </SwipeableCustomerDrawer>
  );
};

export default ChangeCurrency;
