import { memo } from 'react';

import { useTranslation } from 'next-export-i18n';

import { useRestaurant } from '@/hooks';
import { QlubLogo } from '@/icons';
import LanguageSwitcher from '@clubpay/customer-common-module/src/component/LanguageSwitcher';
import LanguageDrawer from '@clubpay/customer-common-module/src/component/LanguageSwitcher/Drawer/index';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { Stack } from '@mui/material';

const LanguageSelector = memo(function LanguageSelector() {
  const { theme } = useQlubRouter();
  const { t } = useTranslation();

  const { data } = useRestaurant();

  return (
    <>
      <Stack
        direction='row'
        gap='8px'
        justifyContent='center'
        alignItems='center'
      >
        <LanguageSwitcher />
        {data?.config.hideLogo !== true && (
          <QlubLogo
            color={theme === 'black' ? 'rgb(105, 105, 105)' : undefined}
          />
        )}
      </Stack>
      <LanguageDrawer drawerTitle={t('Change Language')} />
    </>
  );
});

export default LanguageSelector;
