import { useState } from 'react';

import Link from 'next/link';

import { useTranslation } from 'next-export-i18n';

import { PhoneInput, SectionTitle } from '@/components';
import { usePaymentLink, useRestaurant } from '@/hooks';
import { useStore } from '@/store';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { interpolateT } from '@clubpay/customer-common-module/src/hook/translations';
import { EmailOutlined } from '@mui/icons-material';
import {
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '@qlub-dev/qlub-kit';

import styles from './styles.module.scss';

const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const lettersOnlyRegX = /^[a-zA-Z]+$/;

export default function Content({ onClose, proceedPayment, dsi }: any) {
  const { t } = useTranslation();
  const { data } = useRestaurant();
  const { url, lang } = useQlubRouter();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dialCode, setDialCode] = useState('');
  const setUserInfo = useStore(state => state.setUserInfo);
  const { data: payment } = usePaymentLink();

  const collectCustomerEmail = data?.config.paymentLinkCollectCustomerEmail;
  const collectCustomerMobile = data?.config.paymentLinkCollectCustomerMobile;
  const collectCustomerName = payment?.meta?.isMultiUse;

  function saveData() {
    setUserInfo({
      email,
      ...(/\d{4,}/g.test(phone) ? { phone } : {}),
      firstName,
      lastName,
      dsi,
    });
    onClose();
    proceedPayment?.();
  }

  const disableButton = () => {
    const isValidName = firstName.length > 0 && lastName.length > 0;
    const isValidEmail = emailRegX.test(email);
    const isValidMobile = /\d{7,15}/g.test(phone);
    const mobile = phone;
    if (collectCustomerName) {
      if (!isValidName) return true;
      if (!collectCustomerEmail && !collectCustomerMobile) return false;
    }
    if (
      data?.config.paymentLinkCollectCustomerEmail &&
      data?.config.paymentLinkCollectCustomerMobile &&
      email &&
      phone
    )
      return !(
        isValidEmail &&
        (isValidMobile || mobile.replace(/[^\d]/g, '') === dialCode)
      );
    return !(isValidEmail || isValidMobile);
  };

  const isAlpha = (word: string) => {
    if (lettersOnlyRegX.test(word) || word.length === 0) return true;
    return false;
  };

  return (
    <>
      <SectionTitle
        title={t('your-details')}
        description={t('please-provide-your-details-for-better-communication')}
        onClose={onClose}
        sx={{ title: 'title_lg' }}
      />
      {collectCustomerName && (
        <Box>
          <Box width='50%' display='inline-block'>
            <SectionTitle title={t('first-name')} />
            <Container>
              <TextField
                placeholder={t('first-name')}
                name='first-name'
                fullWidth
                type='text'
                required
                value={firstName}
                onChange={e =>
                  isAlpha(e.target.value) && setFirstName(e.target.value)
                }
              />
            </Container>
          </Box>
          <Box width='50%' display='inline-block'>
            <SectionTitle title={t('last-name')} />
            <Container>
              <TextField
                placeholder={t('last-name')}
                name='last-name'
                fullWidth
                type='text'
                required
                value={lastName}
                onChange={e =>
                  isAlpha(e.target.value) && setLastName(e.target.value)
                }
              />
            </Container>
          </Box>
        </Box>
      )}
      {collectCustomerEmail && (
        <>
          <SectionTitle title={t('email')} />
          <Container>
            <TextField
              placeholder={t('email')}
              name='email'
              fullWidth
              type='email'
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Container>
        </>
      )}
      {collectCustomerMobile && (
        <>
          <SectionTitle title={t('mobile')} />
          <Container>
            <PhoneInput
              country={data?.country.code}
              value={phone}
              onChange={({ value, data }) => {
                setPhone(value);
                setDialCode(String(data.dialCode));
              }}
              hideClearButton
            />
          </Container>
        </>
      )}
      <Container sx={{ paddingY: '24px' }}>
        <Button
          fullWidth
          variant='contained'
          disabled={disableButton()}
          onClick={() => saveData()}
        >
          {t('proceed-to-pay')}
        </Button>
      </Container>
      <Container sx={{ paddingBottom: '24px' }}>
        <Typography variant='caption' color='var(--onSurfaceColors-disabled)'>
          {interpolateT(
            t('by-clicking-on-pay-you-agree-with-the-data-you-filled-out-and'),
            {
              term: (
                <Link
                  href={{
                    pathname: '/qr/[cc]/terms',
                    query: {
                      cc: url.cc || data?.country.code,
                      ...(lang ? { lang } : {}),
                    },
                  }}
                  className={styles.link}
                >
                  {t('terms-of-use')}
                </Link>
              ),
            },
          )}
        </Typography>
      </Container>
    </>
  );
}
